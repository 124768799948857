import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import RemoteArticles from 'components/Internet_Access/IntroInternet';
import NavButtons from 'components/Internet_Access/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Fernzugriff' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Greifen Sie auf Ihre INSTAR IP-Kamera über das Internet mit dem integrierten Point2Point- oder DDNS-Dienst zu. Richten Sie Ihren Router so ein, dass Sie vollen Zugriff auf Ihre Kamera haben, wenn Sie nicht zu Hause sind.' image='/images/Search/IA_SearchThumb_InternetAccess.png' twitter='/images/Search/IA_SearchThumb_InternetAccess.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationDE={props.location.pathname} locationEN='/en/Internet_Access/' locationFR='/fr/Internet_Access/' crumbLabel="Remote Access" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "fernzugriff",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fernzugriff",
        "aria-label": "fernzugriff permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fernzugriff`}</h1>
    <RemoteArticles mdxType="RemoteArticles" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      